
.bb_txt1{
    font-size: 1.5rem !important;
font-weight: lighter !important;
letter-spacing: .125rem !important;

}
.bb_txt2{
font-weight: 700 !important;
    font-size: 3rem !important;
    letter-spacing: .125rem !important;
    color: var(--color1);
}
.bb_txt3{
font-size: .9rem !important;
font-style: italic;
letter-spacing: .125rem !important;
padding-bottom: 10px;

}
.bb_services{
   min-height: 200px;
   background-color: #eee;
   border-radius: 20px; 
   cursor: pointer;
  
}
.bb_services:hover{
    background-color: white;
    transition:all linear 2s ;
  
   
 }
.bb_services_head{
color: var(--color1);
font-size: 1.3rem !important;
letter-spacing: .125rem !important;
font-weight: 700 !important;
padding: 15px 0px;
text-align: center;

}

.bb_services_desc{
    font-size: .9rem !important;
    letter-spacing: .125rem !important;
    padding: 15px !important;
text-align: left;

}
.bg_quote{
  
background: linear-gradient(rgba(0,0,0,0.65),rgba(0,0,0,0.65)),url('../../images/quote.jpg');
height: 50vh;
    width: 100%;
    overflow: hidden;
    object-fit: contain;
    background-position: 50% 20%;
    background-size: cover;
    background-repeat: no-repeat;

}
.bg_quote_txt1{
color: var(--color3);
/* font-weight: lighter !important; */
letter-spacing: .125rem !important;
padding-top: 18vh ;
padding-bottom: 2vh;
text-align: center;
font-size: 1.5rem !important;

}
.bg_quote_txt2{
color: white;
font-weight: 700 !important;
font-size: 3rem !important;
text-align: center;

height: 100%;
}


@media (max-width:500px) {
    .bg_quote_txt2{
           font-size: 2rem !important;
       
        }
}