
.Footer_Items_Block {
    background-color:var(--color2);
}
.Footer_Items {
    height: 90px;
    color:white;
    font-family: var(--fontfamily);
font-weight: 600;
    text-align: center;
    align-items: center;
    display: flex !important;
    justify-content: center;
}
.Footer_Items_NavLink {
    font-size: 0.85rem;
    text-align: center;
    /* height: 15px; */
    margin-top: 10px;
}
.Footer_Items_NavLink1 {
    display: inline-block;
    width: 100px;
    border-right: 2px solid white;
}
.Footer_Items_NavLink2 {
    display: inline-block;
    width: 140px;

    /* border-right: 2px solid white; */
}
.Footer_Items_NavLink3 {
    display: inline-block;
    width: 80px;
}
.Footer_Items a {
    text-decoration: none;
    font-size: 0.86rem;
    color: white;
}

.Footer_Items_P_Div {

    margin-top: 10px;
}

.Footer_Items_P {
    text-align: center;
    font-size: 0.95rem;
    margin: 0px;
    margin-block: 0px;
}



@media (max-width: 420px) {
    .Footer_Items_NavLink1 {
        display: inline-block;
        width: 100px;
    }
    .Footer_Items_NavLink2 {
        display: inline-block;
        width: 140px;
        /* border-right: 2px solid white; */
    }
    .Footer_Items_NavLink3 {
        display: inline-block;
        width: 90px;
    }
    .Footer_Items a {
        text-decoration: none;
        font-size: 0.68rem;
        color: white;
    }
    .Footer_Items_P {
        text-align: center;
        font-size: 0.77rem;
    }
}