.Navbar_Items {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
   position: fixed; 
    top: 0;
    z-index: 101;
    background:white;
    /* border-bottom-style: solid;
    border-bottom-color:#eeee;
    border-bottom-width: 3px; */

}
.Navbar_Link1{
    
    color:var(--color3) !important;

    text-decoration: none !important;
    font-weight: 500 !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    cursor: pointer !important;
    line-height: 1.5 !important;
}
.Navbar_ItemsA {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
       background:white;
   position: fixed; 
    top: 0px;
    z-index: 101;
    transition: all linear .3s;
     /* border-style: solid; */
    /* border-color: var(--color3); */
    /* border-width: 3px; */

  

}
.Grid_MC{
    height: 100%;
    align-content: center;
    justify-content:end;
    padding-right: 60px;
}
.Navbar_Items1 {
    width: 100%;
   padding: 15px 0px;
    display: flex;
    justify-content: space-between;
 
    /* color: black; */

    z-index: 101;
}
.Navbar_Link2 {
    
    border-bottom-width: 2px;
    text-decoration: solid;
    color:var(--color3);

    font-weight: 500;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom-style: solid;
    padding-bottom: 5px;

}
.Navbar_Link2N {
    
    border-bottom-width: 2px;
    text-decoration: solid;
    color: var(--blueColor3);
    font-weight: 500;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom-style: solid;
    padding-bottom: 5px;

}
.icon_gridM{text-align: start;
padding-left: 20px;
align-self: center;
cursor: pointer;}
.icons_nav{
    padding-left: 5px;
    padding-right: 5px;
    font-size: 21px !important;
}
.cart_count{
    position: absolute;
    padding: 0;
    font-size: 14px;
    transform: translate(-36%,-25%);
    min-width: 18px;
    text-align: center;
    height: 18px;
    border-radius: 50%;
    background-color: #c00;
    padding-top: 1px;
}
.icon_ham{
    color:#eeee;
    font-size: 26px;
    padding-left: 20px;
    margin-top: 2px;
    /* font-weight: 500 !important; */
    align-self: center;
    padding-right: 3vw;
}
.icon_gridM1{
   display: none;
}
@media (max-width:500px) {


    .Grid_MC{
       display: none;
    }
    .icon_gridM1{
        display: block;

        align-self: center;
        text-align-last: end;
    }
    .Navbar_Items1{
        display: none;
    }
    .icon_gridM {
        padding-left: 5px;

    }
    .gird_nav {
        padding-right: 0px !important;
    }
}