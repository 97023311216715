.contact_bg{
    background: #eee;

    height: 30vh;
    align-content: center;
}
.contact_head{
    text-align: center;
    font-size: 19px !important;
    padding-top: 20px;
    padding-bottom: 20px;
    color: var(--color2);
}
.contact_subhead{
    font-size: 25px !important;
    color: var(--color2);
    font-weight: 600 !important;
    text-align: center;
    padding-bottom: 20px;

}
.btn_contact{
    width: 95% !important;
    font-weight: 600 !important;
    background: var(--color2) !important;

}
.iconC{
    font-size: 45px;
    color: var(--color2);
    padding-bottom: 5px;

}
.iconC_head{
    font-size: 25px !important;
    color: black;
    font-weight: 600 !important;
    padding-bottom: 5px;
}
.iconC_text{
    font-size: 18px !important;
    color: black;
}
.ContactUs_Map {
    width: 100%;
    height: 390px;
}
